<template>
  <Authenticated>
    <h1>{{ $t("Projects") }}</h1>

    <div class="card-list" v-if="this.projects === null">
      <FreeStyleShimmer width="100%" height="5em"></FreeStyleShimmer>
      <FreeStyleShimmer width="100%" height="5em"></FreeStyleShimmer>
      <FreeStyleShimmer width="100%" height="5em"></FreeStyleShimmer>
    </div>
    <div class="card-list" v-else>
      <router-link
        v-for="project in this.projects"
        :key="project.id"
        class="link"
        :to="{ name: 'project-detail', params: { id: project.id } }"
      >
        <div class="card">
          <div class="card__title">{{ project.name }}</div>
          <div class="card__description">
            {{ project.location }}<br />
            {{ $t("sensor", project.sensor_count) }}
          </div>
        </div>
      </router-link>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "./Authenticated.vue";
import { FreeStyleShimmer } from "vue3-shimmer";

export default {
  components: {
    Authenticated,
    FreeStyleShimmer,
  },
  data() {
    return {
      projects: null,
    };
  },
  mounted() {
    this.$http
      .get("/measurements/projects/")
      .then((response) => {
        this.projects = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.link {
  text-decoration: none;
  color: inherit;
}
</style>
